import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

export const rootWrapper = ({ element }) => {

  return <div>
    <Helmet>
      <meta name="description" content="Gruppe für schwule Jungs ab 30 Jahren in Nürnberg"></meta>
      <meta name="abstract" content="Wir sind die Gruppe für schwule Jungs im Alter ab 30 Jahren im Fliederlich e.V. Nürnberg und laden Dich ein zu geselligen Abenden, Theaterbesuchen, Outdoor-Aktivitäten, uvm."></meta>
      <meta name="keywords" content="schwule Jungs Nürnberg, gay Nürnberg, gays Nürnberg, schwul Nürnberg, schwul Franken, schwul Freizeit, schwule Freizeitgruppe, Schwulengruppe, homosexuell Nürnberg, Szene Nürnberg, schwul Weggehen Nürnberg, schwul Fortgehen Nürnberg, schwuler Stammtisch Nürnberg, neu schwul Nürnberg, schwul community Nürnberg, gay scene nuremberg, gay community nuremberg, SMAG, SMAG Nürnberg"></meta>
      <meta name="news_keywords" content="schwule Jungs Nürnberg, gay Nürnberg, gays Nürnberg, schwul Nürnberg, schwul Franken, schwul Freizeit, schwule Freizeitgruppe, Schwulengruppe, homosexuell Nürnberg, Szene Nürnberg, schwul Weggehen Nürnberg, schwul Fortgehen Nürnberg, schwuler Stammtisch Nürnberg, neu schwul Nürnberg, schwul community Nürnberg, gay scene nuremberg, gay community nuremberg, SMAG, SMAG Nürnberg"></meta>
      <meta name="rights" content="Copyright © Fliederlich e.V. Nürnberg" />
      <meta name="robots" content="follow, index" />
      <meta http-equiv="content-language" content="de" />
    </Helmet>

    <div className="container py-3 is-max-widescreen">
      <div className="box has-background-white p-4 is-ancestor is-vertical">
        <div className="tile">
          <div className="tile is-3 is-vertical is-vbottom">
            <a href="/" title="Startseite" className="pt-auto" rel="home">
              <StaticImage src="./images/logo.png" alt="Startseite" />
            </a>
          </div>
          <div className="tile is-9 is-vertical">
            <div className="tile">
              <h1 className="title is-3">Schwul in Nürnberg - und mittendrin</h1>
            </div>
            <div className="tile pt-1">
              <h2 className="title is-6">im <a className="tag is-info is-light" href="http://www.fliederlich.de" target="_blank" rel="noreferrer">Fliederlich e.V. Nürnberg</a></h2>
            </div>
          </div>

        </div>
        {element}
      </div>
        <div className="has-text-centered">
          Copyright © 2024 Fliederlich e.V., Sandstraße 1 in 90443 Nürnberg. Alle Rechte vorbehalten.
        </div>
      </div>
  </div>;


};